// Bootstrap
$primary: #5BBF22;

@import "~bootstrap/scss/bootstrap";

body {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body.my-login-page {
  background-color: #f7f9fb;
  font-size: 14px;
}

.my-login-page {
  background-color: #f7f9fb;
  font-size: 14px;

  .brand {
    overflow: hidden;
    margin: 40px auto;
    text-align: center;

    img {
      width: 40%;
    }
  }

  .card-wrapper {
    width: 400px;
  }

  .card {
    border-color: transparent;
    box-shadow: 0 4px 8px rgba(0,0,0,.05);
  }

  .card.fat {
    padding: 10px;
  }

  .card .card-title {
    margin-bottom: 30px;
  }

  .form-control {
    border-width: 2.3px;
  }

  .form-group label {
    width: 100%;
  }

  .btn.btn-block {
    padding: 12px 10px;
  }

  .footer {
    margin: 40px 0;
    color: #888;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .my-login-page .card-wrapper {
    width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 320px) {
  .my-login-page .card.fat {
    padding: 0;
  }

  .my-login-page .card.fat .card-body {
    padding: 15px;
  }
}

@mixin loader12(
  $size: $loader-size,
  $color: #f5f5f5,
  $duration: $loader-animation-duration,
  $gap: $loader-gap,
  $align: null
) {
  $unique-name: unique-id();
  display: inline-block;
  width: $size;
  height: $size;
  border-radius: 50%;
  position: relative;
  animation: #{'loader12-'}#{$unique-name} $duration linear alternate infinite;
  @if ($align == center) {
    top: -$size * 2;
    margin-left: auto;
    margin-right: auto;
  }
  @if ($align == middle) {
    top: -11px;
    //margin: -($size * 2 + $size / 2) auto 0;
  } @else {
    top: -$size * 2;
  }
  @keyframes #{'loader12-'}#{$unique-name} {
    0% {
      box-shadow: ((- $gap) * 2) $size * 2 0 2px $color,
        (- $gap) $size * 2 0 0 rgba($color, 0.2),
      0 ($size * 2) 0 0 rgba($color, 0.2),
        ($gap) ($size * 2) 0 0 rgba($color, 0.2),
        ($gap * 2) ($size * 2) 0 0 rgba($color, 0.2);
    }

    25% {
      box-shadow: ((- $gap) * 2) ($size * 2) 0 0 rgba($color, 0.2),
        (- $gap) ($size * 2) 0 2px $color,
      0 ($size * 2) 0 0 rgba($color, 0.2),
        ($gap) ($size * 2) 0 0 rgba($color, 0.2),
        ($gap * 2) ($size * 2) 0 0 rgba($color, 0.2);
    }

    50% {
      box-shadow: ((- $gap) * 2) ($size * 2) 0 0 rgba($color, 0.2),
        (- $gap) ($size * 2) 0 0 rgba($color, 0.2),
      0 ($size * 2) 0 2px $color,
        ($gap) ($size * 2) 0 0 rgba($color, 0.2),
        ($gap * 2) ($size * 2) 0 0 rgba($color, 0.2);
    }

    75% {
      box-shadow: ((- $gap) * 2) ($size * 2) 0 0 rgba($color, 0.2),
        (- $gap) ($size * 2) 0 0 rgba($color, 0.2),
      0 ($size * 2) 0 0 rgba($color, 0.2),
        ($gap) ($size * 2) 0 2px $color,
        ($gap * 2) ($size * 2) 0 0 rgba($color, 0.2);
    }

    100% {
      box-shadow: ((- $gap) * 2) ($size * 2) 0 0 rgba($color, 0.2),
        (- $gap) ($size * 2) 0 0 rgba($color, 0.2),
      0 ($size * 2) 0 0 rgba($color, 0.2),
        ($gap) ($size * 2) 0 0 rgba($color, 0.2),
        ($gap * 2) ($size * 2) 0 2px $color;
    }
  }
}

.loader12 {
  @include loader12($size: 5px, $gap: 30px, $duration: 1s, $align: middle);
}
